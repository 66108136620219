/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const Footer = ({style}) => {
  const footerLinks = [
    {
      title: "Product",
      links: [
        { name: "Technologies", url: "/technologies" },
        { name: "Programmes", url: "/programmes" },
      ],
    },
    {
      title: "Company",
      links: [
        { name: "About us", url: "/about" },
        { name: "News", url: "/events" }
      ],
    },
    {
      title: "Resources",
      links: [
        { name: "Events", url: "/events" },
        { name: "Help centre", url: "/contactus" }
      ],
    },
    {
      title: "Social",
      links: [
        { name: "Twitter", url: "https://x.com/sodharktech" },
        { name: "Facebook", url: "https://www.facebook.com/profile.php?id=61563603296582" }
      ],
    },
    {
      title: "Legal",
      links: [
        { name: "Terms", url: "/termpolicy" },
        { name: "Privacy", url: "/privacy" },
      ],
    },
  ];

  return (
    <FooterWrapper style={style}>
      <FooterContent>
        <LinkColumns>
          {footerLinks.map((column, index) => (
            <LinkColumn key={index}>
              <ColumnTitle>{column.title}</ColumnTitle>
              <LinkList>
                {column.links.map((link, linkIndex) => (
                  <LinkItem key={linkIndex}>
                    {
                      link.name.toLowerCase() == 'facebook' || link.name.toLowerCase() == 'twitter' ? 
                        <Link href={link.url} target="_blank">
                          {link.name}
                          {link.isNew && <NewBadge>New</NewBadge>}
                        </Link> :
                        <Link href={link.url} >
                          {link.name}
                          {link.isNew && <NewBadge>New</NewBadge>}
                        </Link>
                    }
                  </LinkItem>
                ))}
              </LinkList>
            </LinkColumn>
          ))}
        </LinkColumns>
      </FooterContent>
      <BottomBar>
        <Logo
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/4431a5ed8d8d722625f25c6bb9833c0bcc9f17dedf1010c2c900bbaf7aaecad2?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
          alt="Sodhark Logo"
        />
        <Copyright>© 2024 Sodhark. All rights reserved.</Copyright>
      </BottomBar>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.footer`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 79px;
  font-family: Inter, sans-serif;
  border-top: 1px solid #e5e7ec;
  padding-top: 50px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const FooterContent = styled.div`
  width: 100%;
  max-width: 1280px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const LinkColumns = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  justify-content: space-between;
  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

const LinkColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ColumnTitle = styled.h3`
  color: #667085;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 16px;
`;

const LinkList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const LinkItem = styled.li`
  margin-bottom: 12px;
`;

const Link = styled.a`
  color: #475467;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  display: flex;
  align-items: center;
`;

const NewBadge = styled.span`
  background-color: #ecfdf3;
  color: #027a48;
  font-size: 12px;
  font-weight: 500;
  padding: 2px 8px;
  border-radius: 16px;
  margin-left: 8px;
`;

const BottomBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  margin-top: 64px;
  border-top: 1px solid #e4e7ec;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 40px;
    padding: 20px;
  }
`;

const Logo = styled.img`
  width: 164px;
  height: auto;
`;

const Copyright = styled.p`
  color: #667085;
  font-size: 16px;
  font-weight: 400;
  @media (max-width: 991px) {
    margin-top: 16px;
  }
`;

export default Footer;
